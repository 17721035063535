import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/64666590fff24d3b7192552acc988118/e2731/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAgABAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAfbKJlsIMrXOZ//EABYQAQEBAAAAAAAAAAAAAAAAAAEgAP/aAAgBAQABBQKmV3//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BdP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABkQAAMBAQEAAAAAAAAAAAAAAAABEBEhMf/aAAgBAQABPyFnYxUozMMX4f/aAAwDAQACAAMAAAAQpwIO/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQMBAT8QyLGiP//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxBw/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITGBUf/aAAgBAQABPxDhqCukqKhh1Kr25UsGZ5FTjfkXYB7sRwTK1n2f/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/64666590fff24d3b7192552acc988118/e5166/01.jpg",
              "srcSet": ["/static/64666590fff24d3b7192552acc988118/f93b5/01.jpg 300w", "/static/64666590fff24d3b7192552acc988118/b4294/01.jpg 600w", "/static/64666590fff24d3b7192552acc988118/e5166/01.jpg 1200w", "/static/64666590fff24d3b7192552acc988118/e2731/01.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3c9c59cc3e980dad88de08c25e054da2/e2731/02.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgEABf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHthlZrkZsJhk//xAAWEAEBAQAAAAAAAAAAAAAAAAABADD/2gAIAQEAAQUCzW//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BdP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABkQAQEAAwEAAAAAAAAAAAAAAAEAEBGBIP/aAAgBAQABPyFu4Y75ZtLf/9oADAMBAAIAAwAAABDjxgP/xAAXEQEAAwAAAAAAAAAAAAAAAAABABBB/9oACAEDAQE/EMtBP//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxBw/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERMSGBEJHB/9oACAEBAAE/EK3Am26dEFXei3oaGMbzyYqJ2f/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "02",
              "title": "02",
              "src": "/static/3c9c59cc3e980dad88de08c25e054da2/e5166/02.jpg",
              "srcSet": ["/static/3c9c59cc3e980dad88de08c25e054da2/f93b5/02.jpg 300w", "/static/3c9c59cc3e980dad88de08c25e054da2/b4294/02.jpg 600w", "/static/3c9c59cc3e980dad88de08c25e054da2/e5166/02.jpg 1200w", "/static/3c9c59cc3e980dad88de08c25e054da2/e2731/02.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e746ccc352c79e610cb1bcab38e52930/e2731/03.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHtlCK2pFEoSf/EABcQAQADAAAAAAAAAAAAAAAAAAEAEDD/2gAIAQEAAQUCtwWf/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AXT/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ak//xAAZEAADAQEBAAAAAAAAAAAAAAAAARARIYH/2gAIAQEAAT8hZtexnYzDBn//2gAMAwEAAgADAAAAEJvPA//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEP/aAAgBAwEBPxDUF//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxBw/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERITFB/9oACAEBAAE/EOWU3wg2dh14xC/EhR0b00NE8HylP//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "03",
              "title": "03",
              "src": "/static/e746ccc352c79e610cb1bcab38e52930/e5166/03.jpg",
              "srcSet": ["/static/e746ccc352c79e610cb1bcab38e52930/f93b5/03.jpg 300w", "/static/e746ccc352c79e610cb1bcab38e52930/b4294/03.jpg 600w", "/static/e746ccc352c79e610cb1bcab38e52930/e5166/03.jpg 1200w", "/static/e746ccc352c79e610cb1bcab38e52930/e2731/03.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0f49ac9e7faabe0f9dfafc4e60d7724d/e2731/04.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgEABf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHuBgmuGbCYZP/EABYQAQEBAAAAAAAAAAAAAAAAAAEAMP/aAAgBAQABBQLNb//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwF0/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAGRAAAwADAAAAAAAAAAAAAAAAAAERECAx/9oACAEBAAE/IXlk1YyL4f/aAAwDAQACAAMAAAAQE8YD/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAQ/9oACAEDAQE/ENQX/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxC4z//EABwQAAMBAAIDAAAAAAAAAAAAAAABESEQMUFhgf/aAAgBAQABPxDoJ3zSGlBekIMY3vJla+n/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "04",
              "title": "04",
              "src": "/static/0f49ac9e7faabe0f9dfafc4e60d7724d/e5166/04.jpg",
              "srcSet": ["/static/0f49ac9e7faabe0f9dfafc4e60d7724d/f93b5/04.jpg 300w", "/static/0f49ac9e7faabe0f9dfafc4e60d7724d/b4294/04.jpg 600w", "/static/0f49ac9e7faabe0f9dfafc4e60d7724d/e5166/04.jpg 1200w", "/static/0f49ac9e7faabe0f9dfafc4e60d7724d/e2731/04.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/467a8e357578bba1835b4701401afca6/e2731/05.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB9uLgNGx0kOZP/8QAFxABAQEBAAAAAAAAAAAAAAAAARAAIP/aAAgBAQABBQKteV3/xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BdP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABkQAAMBAQEAAAAAAAAAAAAAAAABERAhMf/aAAgBAQABPyFlb13fLjIiL8P/2gAMAwEAAgADAAAAEMPPDv/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEP/aAAgBAwEBPxDUF//EABcRAAMBAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QuM//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMVFxgf/aAAgBAQABPxC9Z2CNCpUTWIfYFunqyVAVsLDWLsI9LiHAmXrPM//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "05",
              "title": "05",
              "src": "/static/467a8e357578bba1835b4701401afca6/e5166/05.jpg",
              "srcSet": ["/static/467a8e357578bba1835b4701401afca6/f93b5/05.jpg 300w", "/static/467a8e357578bba1835b4701401afca6/b4294/05.jpg 600w", "/static/467a8e357578bba1835b4701401afca6/e5166/05.jpg 1200w", "/static/467a8e357578bba1835b4701401afca6/e2731/05.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6944a3349164ab402feaf520c7becd5c/e2731/06.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHthgrYRRKEn//EABcQAQADAAAAAAAAAAAAAAAAAAEAEDD/2gAIAQEAAQUCbcFn/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AXT/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ak//xAAYEAADAQEAAAAAAAAAAAAAAAAAARARIf/aAAgBAQABPyEd3tKMUcxZ/9oADAMBAAIAAwAAABAHy0P/xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8QcH//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8QcP/EABsQAQACAwEBAAAAAAAAAAAAAAEAESExgUFx/9oACAEBAAE/EEhgVg6jXyVNNyz7UqGxxcAN09i5mUQNSxKvs//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "06",
              "title": "06",
              "src": "/static/6944a3349164ab402feaf520c7becd5c/e5166/06.jpg",
              "srcSet": ["/static/6944a3349164ab402feaf520c7becd5c/f93b5/06.jpg 300w", "/static/6944a3349164ab402feaf520c7becd5c/b4294/06.jpg 600w", "/static/6944a3349164ab402feaf520c7becd5c/e5166/06.jpg 1200w", "/static/6944a3349164ab402feaf520c7becd5c/e2731/06.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6e5b4ab400ca6f32a63abe6c69043703/e2731/07.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7c3JIwuWhGYn/8QAFhABAQEAAAAAAAAAAAAAAAAAAQAw/9oACAEBAAEFAs1v/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AXT/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ak//xAAYEAEBAAMAAAAAAAAAAAAAAAABABEwMf/aAAgBAQABPyF0s2F5f//aAAwDAQACAAMAAAAQ1wYP/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EHB//8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/EHD/xAAaEAEBAQEAAwAAAAAAAAAAAAABABEhEIGh/9oACAEBAAE/EBzjI71+WTJZMy+TROvd/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "07",
              "title": "07",
              "src": "/static/6e5b4ab400ca6f32a63abe6c69043703/e5166/07.jpg",
              "srcSet": ["/static/6e5b4ab400ca6f32a63abe6c69043703/f93b5/07.jpg 300w", "/static/6e5b4ab400ca6f32a63abe6c69043703/b4294/07.jpg 600w", "/static/6e5b4ab400ca6f32a63abe6c69043703/e5166/07.jpg 1200w", "/static/6e5b4ab400ca6f32a63abe6c69043703/e2731/07.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      